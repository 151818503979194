// Workbox Version 6.5.4

import type { ExtendableEvent, FetchEvent, Request, Response, RequestInfo } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

export type ModulePathCallback = () => string;

export interface Workbox {
    backgroundSync: BackgroundSync;
    broadcastUpdate: BroadcastUpdate;
    cacheableResponse: CacheableResponse;
    core: Core;
    expiration: Expiration;
    googleAnalytics: GoogleAnalytics;
    navigationPreload: NavigationPreload;
    precaching: Precaching;
    rangeRequests: RangeRequests;
    routing: Routing;
    strategies: Strategies;
    streams: Streams;
    recipes: Recipes;

    setConfig: (options?: {
        debug?: boolean;
        modulePathPrefix?: string | ModulePathCallback
    }) => void
}

/* ------------------------- */
/* ---- Background Sync ---- */
/* ------------------------- */
// region

export interface BackgroundSync {
    BackgroundSyncPlugin: any
    Queue: any
    QueueOptions: any
    QueueStore: any
    StorableRequest: any
}

// endregion

/* -------------------------- */
/* ---- Broadcast Update ---- */
/* -------------------------- */
// region

export interface BroadcastUpdate {
  BroadcastCacheUpdate: any;
  BroadcastCacheUpdateOptions: any;
  BroadcastUpdatePlugin: any;
  responsesAreSame: any;
}

// endregion

/* ---------------------------- */
/* ---- Cacheable Response ---- */
/* ---------------------------- */
// region

export interface CacheableResponse {
    CacheableResponse: any;
    CacheableResponseOptions: any;
    CacheableResponsePlugin: any;
}

// endregion

/* -------------- */
/* ---- Core ---- */
/* -------------- */
// region Core

export interface Core {
    assert: any;
    cacheMatchIgnoreParams: any;
    cacheNames: any;
    canConstructReadableStream: any;
    canConstructResponseFromBodyStream: any;
    dontWaitFor: any;
    Deferred: any;
    executeQuotaErrorCallbacks: any;
    getFriendlyURL: any;
    logger: any;
    resultingClientExists: any;
    timeout: any;
    waitUntil: any;
    WorkboxError: any;
    clientsClaim: any;
    copyResponse: any;
    registerQuotaErrorCallback: any;
    setCacheNameDetails: any;
    skipWaiting: any;
}

// region types
export interface MapLikeObject {
  [key: string]: any;
}

/**
 * Using a plain `MapLikeObject` for now, but could extend/restrict this
 * in the future.
 */
export type PluginState = MapLikeObject;

/**
 * Options passed to a `RouteMatchCallback` function.
 */
export interface RouteMatchCallbackOptions {
  event: ExtendableEvent;
  request: Request;
  sameOrigin: boolean;
  url: URL;
}

/**
 * The "match" callback is used to determine if a `Route` should apply for a
 * particular URL and request. When matching occurs in response to a fetch
 * event from the client, the `event` object is also supplied. However, since
 * the match callback can be invoked outside of a fetch event, matching logic
 * should not assume the `event` object will always be available.
 * If the match callback returns a truthy value, the matching route's
 * `RouteHandlerCallback` will be invoked immediately. If the value returned
 * is a non-empty array or object, that value will be set on the handler's
 * `options.params` argument.
 */
export interface RouteMatchCallback {
  (options: RouteMatchCallbackOptions): any;
}

/**
 * Options passed to a `RouteHandlerCallback` function.
 */
export declare interface RouteHandlerCallbackOptions {
  event: ExtendableEvent;
  request: Request;
  url: URL;
  params?: string[] | MapLikeObject;
}

/**
 * Options passed to a `ManualHandlerCallback` function.
 */
export interface ManualHandlerCallbackOptions {
  event: ExtendableEvent;
  request: Request | string;
}

export type HandlerCallbackOptions =
  | RouteHandlerCallbackOptions
  | ManualHandlerCallbackOptions;

/**
 * The "handler" callback is invoked whenever a `Router` matches a URL/Request
 * to a `Route` via its `RouteMatchCallback`. This handler callback should
 * return a `Promise` that resolves with a `Response`.
 *
 * If a non-empty array or object is returned by the `RouteMatchCallback` it
 * will be passed in as this handler's `options.params` argument.
 */
export interface RouteHandlerCallback {
  (options: RouteHandlerCallbackOptions): Promise<Response>;
}

/**
 * The "handler" callback is invoked whenever a `Router` matches a URL/Request
 * to a `Route` via its `RouteMatchCallback`. This handler callback should
 * return a `Promise` that resolves with a `Response`.
 *
 * If a non-empty array or object is returned by the `RouteMatchCallback` it
 * will be passed in as this handler's `options.params` argument.
 */
export interface ManualHandlerCallback {
  (options: ManualHandlerCallbackOptions): Promise<Response>;
}

/**
 * An object with a `handle` method of type `RouteHandlerCallback`.
 *
 * A `Route` object can be created with either an `RouteHandlerCallback`
 * function or this `RouteHandler` object. The benefit of the `RouteHandler`
 * is it can be extended (as is done by the `workbox-strategies` package).
 */
export interface RouteHandlerObject {
  handle: RouteHandlerCallback;
}

/**
 * Either a `RouteHandlerCallback` or a `RouteHandlerObject`.
 * Most APIs in `workbox-routing` that accept route handlers take either.
 */
export type RouteHandler = RouteHandlerCallback | RouteHandlerObject;

export interface HandlerWillStartCallbackParam {
  request: Request;
  event: ExtendableEvent;
  state?: PluginState;
}

export interface HandlerWillStartCallback {
  (param: HandlerWillStartCallbackParam): Promise<void | null | undefined>;
}

export interface CacheDidUpdateCallbackParam {
  cacheName: string;
  newResponse: Response;
  request: Request;
  event: ExtendableEvent;
  oldResponse?: Response | null;
  state?: PluginState;
}

export interface CacheDidUpdateCallback {
  (param: CacheDidUpdateCallbackParam): Promise<void | null | undefined>;
}

export interface CacheKeyWillBeUsedCallbackParam {
  mode: string;
  request: Request;
  event: ExtendableEvent;
  params?: any;
  state?: PluginState;
}

export interface CacheKeyWillBeUsedCallback {
  (param: CacheKeyWillBeUsedCallbackParam): Promise<Request | string>;
}

export interface CacheWillUpdateCallbackParam {
  request: Request;
  response: Response;
  event: ExtendableEvent;
  state?: PluginState;
}

export interface CacheWillUpdateCallback {
  (param: CacheWillUpdateCallbackParam): Promise<
    Response | void | null | undefined
  >;
}

export interface CachedResponseWillBeUsedCallbackParam {
  cacheName: string;
  request: Request;
  cachedResponse?: Response;
  event: ExtendableEvent;
  matchOptions?: CacheQueryOptions;
  state?: PluginState;
}

export interface CachedResponseWillBeUsedCallback {
  (param: CachedResponseWillBeUsedCallbackParam): Promise<
    Response | void | null | undefined
  >;
}

export interface FetchDidFailCallbackParam {
  error: Error;
  originalRequest: Request;
  request: Request;
  event: ExtendableEvent;
  state?: PluginState;
}

export interface FetchDidFailCallback {
  (param: FetchDidFailCallbackParam): Promise<void | null | undefined>;
}

export interface FetchDidSucceedCallbackParam {
  request: Request;
  response: Response;
  event: ExtendableEvent;
  state?: PluginState;
}

export interface FetchDidSucceedCallback {
  (param: FetchDidSucceedCallbackParam): Promise<Response>;
}

export interface RequestWillFetchCallbackParam {
  request: Request;
  event: ExtendableEvent;
  state?: PluginState;
}

export interface RequestWillFetchCallback {
  (param: RequestWillFetchCallbackParam): Promise<Request>;
}

export interface HandlerWillRespondCallbackParam {
  request: Request;
  response: Response;
  event: ExtendableEvent;
  state?: PluginState;
}

export interface HandlerWillRespondCallback {
  (param: HandlerWillRespondCallbackParam): Promise<Response>;
}

export interface HandlerDidErrorCallbackParam {
  request: Request;
  event: ExtendableEvent;
  error: Error;
  state?: PluginState;
}

export interface HandlerDidErrorCallback {
  (param: HandlerDidErrorCallbackParam): Promise<Response | undefined>;
}

export interface HandlerDidRespondCallbackParam {
  request: Request;
  event: ExtendableEvent;
  response?: Response;
  state?: PluginState;
}

export interface HandlerDidRespondCallback {
  (param: HandlerDidRespondCallbackParam): Promise<void | null | undefined>;
}

export interface HandlerDidCompleteCallbackParam {
  request: Request;
  error?: Error;
  event: ExtendableEvent;
  response?: Response;
  state?: PluginState;
}

export interface HandlerDidCompleteCallback {
  (param: HandlerDidCompleteCallbackParam): Promise<void | null | undefined>;
}

/**
 * An object with optional lifecycle callback properties for the fetch and
 * cache operations.
 */
export declare interface WorkboxPlugin {
  cacheDidUpdate?: CacheDidUpdateCallback;
  cachedResponseWillBeUsed?: CachedResponseWillBeUsedCallback;
  cacheKeyWillBeUsed?: CacheKeyWillBeUsedCallback;
  cacheWillUpdate?: CacheWillUpdateCallback;
  fetchDidFail?: FetchDidFailCallback;
  fetchDidSucceed?: FetchDidSucceedCallback;
  handlerDidComplete?: HandlerDidCompleteCallback;
  handlerDidError?: HandlerDidErrorCallback;
  handlerDidRespond?: HandlerDidRespondCallback;
  handlerWillRespond?: HandlerWillRespondCallback;
  handlerWillStart?: HandlerWillStartCallback;
  requestWillFetch?: RequestWillFetchCallback;
}

export interface WorkboxPluginCallbackParam {
  cacheDidUpdate: CacheDidUpdateCallbackParam;
  cachedResponseWillBeUsed: CachedResponseWillBeUsedCallbackParam;
  cacheKeyWillBeUsed: CacheKeyWillBeUsedCallbackParam;
  cacheWillUpdate: CacheWillUpdateCallbackParam;
  fetchDidFail: FetchDidFailCallbackParam;
  fetchDidSucceed: FetchDidSucceedCallbackParam;
  handlerDidComplete: HandlerDidCompleteCallbackParam;
  handlerDidError: HandlerDidErrorCallbackParam;
  handlerDidRespond: HandlerDidRespondCallbackParam;
  handlerWillRespond: HandlerWillRespondCallbackParam;
  handlerWillStart: HandlerWillStartCallbackParam;
  requestWillFetch: RequestWillFetchCallbackParam;
}

// endregion

// endregion

/* -------------------- */
/* ---- Expiration ---- */
/* -------------------- */
// region Expiration

export interface Expiration {
    CacheExpiration: any;
    ExpirationPlugin: any;
    ExpirationPluginOptions: any;
}

// endregion

/* -------------------------- */
/* ---- Google Analytics ---- */
/* -------------------------- */
// region

export interface GoogleAnalytics {
    initialize: any;
    GoogleAnalyticsInitializeOptions: any;
}

// endregion

/* ---------------------------- */
/* ---- Navigation Preload ---- */
/* ---------------------------- */
// region

export interface NavigationPreload {
    disable: any;
    enable: any;
    isSupported: any;
}

// endregion

/* -------------------- */
/* ---- Precaching ---- */
/* -------------------- */
// region

export interface Precaching {
  addPlugins: any;
  addRoute: any;
  cleanupOutdatedCaches: any;
  createHandlerBoundToURL: any;
  getCacheKeyForURL: any;
  matchPrecache: any;
  precache: any;
  precacheAndRoute: any;
  PrecacheController: any;
  PrecacheRoute: any;
  PrecacheStrategy: any;
  PrecacheFallbackPlugin: any;
}

// endregion

/* ------------------------ */
/* ---- Range Requests ---- */
/* ------------------------ */
// region

export interface RangeRequests {
    createPartialResponse: any;
    RangeRequestsPlugin: any;
}

// endregion

/* ----------------- */
/* ---- Routing ---- */
/* ----------------- */
// region

export interface Routing {
  NavigationRoute: typeof NavigationRoute;
  RegExpRoute: typeof RegExpRoute;
  registerRoute: any;
  Route: typeof Route;
  Router: any;
  setCatchHandler: any;
  setDefaultHandler: any;
  NavigationRouteMatchOptions: any;
}

// region utils/constants

export type HTTPMethod = 'DELETE' | 'GET' | 'HEAD' | 'PATCH' | 'POST' | 'PUT';

/**
 * The default HTTP method, 'GET', used when there's no specific method
 * configured for a route.
 */
export declare const defaultMethod: 'GET';

/**
 * The list of valid HTTP methods associated with requests that could be routed.
 */
export declare const validMethods: HTTPMethod[];

// endregion

// region NavigationRoute

export interface NavigationRouteMatchOptions {
  allowlist?: RegExp[];
  denylist?: RegExp[];
}

/**
 * NavigationRoute makes it easy to create a
 * {@link workbox-routing.Route} that matches for browser
 * [navigation requests]{@link https://developers.google.com/web/fundamentals/primers/service-workers/high-performance-loading#first_what_are_navigation_requests}.
 *
 * It will only match incoming Requests whose
 * {@link https://fetch.spec.whatwg.org/#concept-request-mode|mode}
 * is set to `navigate`.
 *
 * You can optionally only apply this route to a subset of navigation requests
 * by using one or both of the `denylist` and `allowlist` parameters.
 *
 * @memberof workbox-routing
 * @extends workbox-routing.Route
 */
export class NavigationRoute extends Route {
  private readonly _allowlist: RegExp[];
  private readonly _denylist: RegExp[];

  /**
   * If both `denylist` and `allowlist` are provided, the `denylist` will
   * take precedence and the request will not match this route.
   *
   * The regular expressions in `allowlist` and `denylist`
   * are matched against the concatenated
   * [`pathname`]{@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLHyperlinkElementUtils/pathname}
   * and [`search`]{@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLHyperlinkElementUtils/search}
   * portions of the requested URL.
   *
   * *Note*: These RegExps may be evaluated against every destination URL during
   * a navigation. Avoid using
   * [complex RegExps](https://github.com/GoogleChrome/workbox/issues/3077),
   * or else your users may see delays when navigating your site.
   *
   * @param {workbox-routing~handlerCallback} handler A callback
   * function that returns a Promise resulting in a Response.
   * @param {Object} options
   * @param {Array<RegExp>} [options.denylist] If any of these patterns match,
   * the route will not handle the request (even if a allowlist RegExp matches).
   * @param {Array<RegExp>} [options.allowlist=[/./]] If any of these patterns
   * match the URL's pathname and search parameter, the route will handle the
   * request (assuming the denylist doesn't match).
   */
  constructor(
    handler: RouteHandler,
    {
        allowlist = [/./],
        denylist = []
    }: NavigationRouteMatchOptions,
  )

  /**
   * Routes match handler.
   *
   * @param {Object} options
   * @param {URL} options.url
   * @param {Request} options.request
   * @return {boolean}
   *
   * @private
   */
  private _match({url, request}: RouteMatchCallbackOptions): boolean;
}

// endregion

// region RegExpRoute

/**
 * RegExpRoute makes it easy to create a regular expression based
 * {@link workbox-routing.Route}.
 *
 * For same-origin requests the RegExp only needs to match part of the URL. For
 * requests against third-party servers, you must define a RegExp that matches
 * the start of the URL.
 *
 * @memberof workbox-routing
 * @extends workbox-routing.Route
 */
export class RegExpRoute extends Route {
  /**
   * If the regular expression contains
   * [capture groups]{@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp#grouping-back-references},
   * the captured values will be passed to the
   * {@link workbox-routing~handlerCallback} `params`
   * argument.
   *
   * @param {RegExp} regExp The regular expression to match against URLs.
   * @param {workbox-routing~handlerCallback} handler A callback
   * function that returns a Promise resulting in a Response.
   * @param {string} [method='GET'] The HTTP method to match the Route
   * against.
   */
  constructor(regExp: RegExp, handler: RouteHandler, method?: HTTPMethod)
}

// endregion

// region Route

/**
 * A `Route` consists of a pair of callback functions, "match" and "handler".
 * The "match" callback determine if a route should be used to "handle" a
 * request by returning a non-falsy value if it can. The "handler" callback
 * is called when there is a match and should return a Promise that resolves
 * to a `Response`.
 *
 * @memberof workbox-routing
 */
export class Route {
  handler: RouteHandlerObject;
  match: RouteMatchCallback;
  method: HTTPMethod;
  catchHandler?: RouteHandlerObject;

  /**
   * Constructor for Route class.
   *
   * @param {workbox-routing~matchCallback} match
   * A callback function that determines whether the route matches a given
   * `fetch` event by returning a non-falsy value.
   * @param {workbox-routing~handlerCallback} handler A callback
   * function that returns a Promise resolving to a Response.
   * @param {string} [method='GET'] The HTTP method to match the Route
   * against.
   */
  constructor(
    match: RouteMatchCallback,
    handler: RouteHandler,
    method?: HTTPMethod,
  );

  /**
   *
   * @param {workbox-routing-handlerCallback} handler A callback
   * function that returns a Promise resolving to a Response
   */
  setCatchHandler(handler: RouteHandler): void;
}

// endregion

// endregion

/* ----------------- */
/* ---- Strategies ---- */
/* ----------------- */
// region

export interface Strategies {
    CacheFirst: typeof CacheFirst;
    CacheOnly: typeof CacheOnly;
    NetworkFirst: typeof NetworkFirst;
    NetworkOnly: typeof NetworkOnly;
    StaleWhileRevalidate: typeof StaleWhileRevalidate;
    Strategy: typeof Strategy;
    StrategyHandler: StrategyHandler;
}

/**
 * An implementation of a [cache-first](https://developer.chrome.com/docs/workbox/caching-strategies-overview/#cache-first-falling-back-to-network)
 * request strategy.
 *
 * A cache first strategy is useful for assets that have been revisioned,
 * such as URLs like `/styles/example.a8f5f1.css`, since they
 * can be cached for long periods of time.
 *
 * If the network request fails, and there is no cache match, this will throw
 * a `WorkboxError` exception.
 *
 * @extends workbox-strategies.Strategy
 * @memberof workbox-strategies
 */
export class CacheFirst extends Strategy {
    /**
     * @private
     * @param {Request|string} request A request to run this strategy for.
     * @param {workbox-strategies.StrategyHandler} handler The event that
     *     triggered the request.
     * @return {Promise<Response>}
     */
    _handle(request: Request, handler: StrategyHandler): Promise<Response>;
}

/**
 * An implementation of a [cache-only](https://developer.chrome.com/docs/workbox/caching-strategies-overview/#cache-only)
 * request strategy.
 *
 * This class is useful if you want to take advantage of any
 * [Workbox plugins](https://developer.chrome.com/docs/workbox/using-plugins/).
 *
 * If there is no cache match, this will throw a `WorkboxError` exception.
 *
 * @extends workbox-strategies.Strategy
 * @memberof workbox-strategies
 */
export class CacheOnly extends Strategy {
    /**
     * @private
     * @param {Request|string} request A request to run this strategy for.
     * @param {workbox-strategies.StrategyHandler} handler The event that
     *     triggered the request.
     * @return {Promise<Response>}
     */
    _handle(request: Request, handler: StrategyHandler): Promise<Response>;
}

export interface NetworkFirstOptions extends StrategyOptions {
    networkTimeoutSeconds?: number;
}

/**
 * An implementation of a
 * [network first](https://developer.chrome.com/docs/workbox/caching-strategies-overview/#network-first-falling-back-to-cache)
 * request strategy.
 *
 * By default, this strategy will cache responses with a 200 status code as
 * well as [opaque responses](https://developer.chrome.com/docs/workbox/caching-resources-during-runtime/#opaque-responses).
 * Opaque responses are are cross-origin requests where the response doesn't
 * support [CORS](https://enable-cors.org/).
 *
 * If the network request fails, and there is no cache match, this will throw
 * a `WorkboxError` exception.
 *
 * @extends workbox-strategies.Strategy
 * @memberof workbox-strategies
 */
export class NetworkFirst extends Strategy {
    private readonly _networkTimeoutSeconds;
    /**
     * @param {Object} [options]
     * @param {string} [options.cacheName] Cache name to store and retrieve
     * requests. Defaults to cache names provided by
     * {@link workbox-core.cacheNames}.
     * @param {Array<Object>} [options.plugins] [Plugins]{@link https://developers.google.com/web/tools/workbox/guides/using-plugins}
     * to use in conjunction with this caching strategy.
     * @param {Object} [options.fetchOptions] Values passed along to the
     * [`init`](https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch#Parameters)
     * of [non-navigation](https://github.com/GoogleChrome/workbox/issues/1796)
     * `fetch()` requests made by this strategy.
     * @param {Object} [options.matchOptions] [`CacheQueryOptions`](https://w3c.github.io/ServiceWorker/#dictdef-cachequeryoptions)
     * @param {number} [options.networkTimeoutSeconds] If set, any network requests
     * that fail to respond within the timeout will fallback to the cache.
     *
     * This option can be used to combat
     * "[lie-fi]{@link https://developers.google.com/web/fundamentals/performance/poor-connectivity/#lie-fi}"
     * scenarios.
     */
    constructor(options?: NetworkFirstOptions);
    /**
     * @private
     * @param {Request|string} request A request to run this strategy for.
     * @param {workbox-strategies.StrategyHandler} handler The event that
     *     triggered the request.
     * @return {Promise<Response>}
     */
    _handle(request: Request, handler: StrategyHandler): Promise<Response>;
    /**
     * @param {Object} options
     * @param {Request} options.request
     * @param {Array} options.logs A reference to the logs array
     * @param {Event} options.event
     * @return {Promise<Response>}
     *
     * @private
     */
    private _getTimeoutPromise;
    /**
     * @param {Object} options
     * @param {number|undefined} options.timeoutId
     * @param {Request} options.request
     * @param {Array} options.logs A reference to the logs Array.
     * @param {Event} options.event
     * @return {Promise<Response>}
     *
     * @private
     */
    _getNetworkPromise({ timeoutId, request, logs, handler, }: {
        request: Request;
        logs: any[];
        timeoutId?: number;
        handler: StrategyHandler;
    }): Promise<Response | undefined>;
}

export interface NetworkOnlyOptions extends Omit<StrategyOptions, 'cacheName' | 'matchOptions'> {
    networkTimeoutSeconds?: number;
}

/**
 * An implementation of a
 * [network-only](https://developer.chrome.com/docs/workbox/caching-strategies-overview/#network-only)
 * request strategy.
 *
 * This class is useful if you want to take advantage of any
 * [Workbox plugins](https://developer.chrome.com/docs/workbox/using-plugins/).
 *
 * If the network request fails, this will throw a `WorkboxError` exception.
 *
 * @extends workbox-strategies.Strategy
 * @memberof workbox-strategies
 */
export class NetworkOnly extends Strategy {
    private readonly _networkTimeoutSeconds;
    /**
     * @param {Object} [options]
     * @param {Array<Object>} [options.plugins] [Plugins]{@link https://developers.google.com/web/tools/workbox/guides/using-plugins}
     * to use in conjunction with this caching strategy.
     * @param {Object} [options.fetchOptions] Values passed along to the
     * [`init`](https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch#Parameters)
     * of [non-navigation](https://github.com/GoogleChrome/workbox/issues/1796)
     * `fetch()` requests made by this strategy.
     * @param {number} [options.networkTimeoutSeconds] If set, any network requests
     * that fail to respond within the timeout will result in a network error.
     */
    constructor(options?: NetworkOnlyOptions);
    /**
     * @private
     * @param {Request|string} request A request to run this strategy for.
     * @param {workbox-strategies.StrategyHandler} handler The event that
     *     triggered the request.
     * @return {Promise<Response>}
     */
    _handle(request: Request, handler: StrategyHandler): Promise<Response>;
}

/**
 * An implementation of a
 * [stale-while-revalidate](https://developer.chrome.com/docs/workbox/caching-strategies-overview/#stale-while-revalidate)
 * request strategy.
 *
 * Resources are requested from both the cache and the network in parallel.
 * The strategy will respond with the cached version if available, otherwise
 * wait for the network response. The cache is updated with the network response
 * with each successful request.
 *
 * By default, this strategy will cache responses with a 200 status code as
 * well as [opaque responses](https://developer.chrome.com/docs/workbox/caching-resources-during-runtime/#opaque-responses).
 * Opaque responses are cross-origin requests where the response doesn't
 * support [CORS](https://enable-cors.org/).
 *
 * If the network request fails, and there is no cache match, this will throw
 * a `WorkboxError` exception.
 *
 * @extends workbox-strategies.Strategy
 * @memberof workbox-strategies
 */
export class StaleWhileRevalidate extends Strategy {
    /**
     * @param {Object} [options]
     * @param {string} [options.cacheName] Cache name to store and retrieve
     * requests. Defaults to cache names provided by
     * {@link workbox-core.cacheNames}.
     * @param {Array<Object>} [options.plugins] [Plugins]{@link https://developers.google.com/web/tools/workbox/guides/using-plugins}
     * to use in conjunction with this caching strategy.
     * @param {Object} [options.fetchOptions] Values passed along to the
     * [`init`](https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch#Parameters)
     * of [non-navigation](https://github.com/GoogleChrome/workbox/issues/1796)
     * `fetch()` requests made by this strategy.
     * @param {Object} [options.matchOptions] [`CacheQueryOptions`](https://w3c.github.io/ServiceWorker/#dictdef-cachequeryoptions)
     */
    constructor(options?: StrategyOptions);
    /**
     * @private
     * @param {Request|string} request A request to run this strategy for.
     * @param {workbox-strategies.StrategyHandler} handler The event that
     *     triggered the request.
     * @return {Promise<Response>}
     */
    _handle(request: Request, handler: StrategyHandler): Promise<Response>;
}

export interface StrategyOptions {
    cacheName?: string;
    plugins?: WorkboxPlugin[];
    fetchOptions?: RequestInit;
    matchOptions?: CacheQueryOptions;
}
/**
 * An abstract base class that all other strategy classes must extend from:
 *
 * @memberof workbox-strategies
 */
export abstract class Strategy implements RouteHandlerObject {
    cacheName: string;
    plugins: WorkboxPlugin[];
    fetchOptions?: RequestInit;
    matchOptions?: CacheQueryOptions;
    protected abstract _handle(request: Request, handler: StrategyHandler): Promise<Response | undefined>;
    /**
     * Creates a new instance of the strategy and sets all documented option
     * properties as public instance properties.
     *
     * Note: if a custom strategy class extends the base Strategy class and does
     * not need more than these properties, it does not need to define its own
     * constructor.
     *
     * @param {Object} [options]
     * @param {string} [options.cacheName] Cache name to store and retrieve
     * requests. Defaults to the cache names provided by
     * {@link workbox-core.cacheNames}.
     * @param {Array<Object>} [options.plugins] [Plugins]{@link https://developers.google.com/web/tools/workbox/guides/using-plugins}
     * to use in conjunction with this caching strategy.
     * @param {Object} [options.fetchOptions] Values passed along to the
     * [`init`](https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch#Parameters)
     * of [non-navigation](https://github.com/GoogleChrome/workbox/issues/1796)
     * `fetch()` requests made by this strategy.
     * @param {Object} [options.matchOptions] The
     * [`CacheQueryOptions`]{@link https://w3c.github.io/ServiceWorker/#dictdef-cachequeryoptions}
     * for any `cache.match()` or `cache.put()` calls made by this strategy.
     */
    constructor(options?: StrategyOptions);
    /**
     * Perform a request strategy and returns a `Promise` that will resolve with
     * a `Response`, invoking all relevant plugin callbacks.
     *
     * When a strategy instance is registered with a Workbox
     * {@link workbox-routing.Route}, this method is automatically
     * called when the route matches.
     *
     * Alternatively, this method can be used in a standalone `FetchEvent`
     * listener by passing it to `event.respondWith()`.
     *
     * @param {FetchEvent|Object} options A `FetchEvent` or an object with the
     *     properties listed below.
     * @param {Request|string} options.request A request to run this strategy for.
     * @param {ExtendableEvent} options.event The event associated with the
     *     request.
     * @param {URL} [options.url]
     * @param {*} [options.params]
     */
    handle(options: FetchEvent | HandlerCallbackOptions): Promise<Response>;
    /**
     * Similar to {@link workbox-strategies.Strategy~handle}, but
     * instead of just returning a `Promise` that resolves to a `Response` it
     * it will return an tuple of `[response, done]` promises, where the former
     * (`response`) is equivalent to what `handle()` returns, and the latter is a
     * Promise that will resolve once any promises that were added to
     * `event.waitUntil()` as part of performing the strategy have completed.
     *
     * You can await the `done` promise to ensure any extra work performed by
     * the strategy (usually caching responses) completes successfully.
     *
     * @param {FetchEvent|Object} options A `FetchEvent` or an object with the
     *     properties listed below.
     * @param {Request|string} options.request A request to run this strategy for.
     * @param {ExtendableEvent} options.event The event associated with the
     *     request.
     * @param {URL} [options.url]
     * @param {*} [options.params]
     * @return {Array<Promise>} A tuple of [response, done]
     *     promises that can be used to determine when the response resolves as
     *     well as when the handler has completed all its work.
     */
    handleAll(options: FetchEvent | HandlerCallbackOptions): [Promise<Response>, Promise<void>];
    _getResponse(handler: StrategyHandler, request: Request, event: ExtendableEvent): Promise<Response>;
    _awaitComplete(responseDone: Promise<Response>, handler: StrategyHandler, request: Request, event: ExtendableEvent): Promise<void>;
}

export class StrategyHandler {
  request: Request;
  url?: URL;
  event: ExtendableEvent;
  params?: any;

  constructor(strategy: Strategy, options: HandlerCallbackOptions);

  fetch(input: RequestInfo): Promise<Response>
  fetchAndCachePut(input: RequestInfo): Promise<Response>
  cacheMatch(key: RequestInfo): Promise<Response | undefined>
  cachePut(key: RequestInfo, response: Response): Promise<boolean>
  getCacheKey(request: Request, mode: 'read' | 'write'): Promise<Request>
  hasCallback<C extends keyof WorkboxPlugin>(name: C): boolean
  runCallbacks<C extends keyof NonNullable<WorkboxPlugin>>(name: C, param: Omit<WorkboxPluginCallbackParam[C], 'state'>): Promise<void> 
  iterateCallbacks<C extends keyof WorkboxPlugin>(name: C): Generator<NonNullable<WorkboxPlugin[C]>>
  waitUntil<T>(promise: Promise<T>): Promise<T>
  doneWaiting(): Promise<void>
  destroy(): void
  _ensureResponseSafeToCache(response: Response): Promise<Response | undefined> 
}

// endregion

/* ----------------- */
/* ---- Streams ---- */
/* ----------------- */
// region

export interface Streams {
  concatenate: any;
  concatenateToResponse: any;
  isSupported: any;
  strategy: any;
  StreamsHandlerCallback: any;
}

// endregion

/* ----------------- */
/* ---- Recipes ---- */
/* ----------------- */
// region
export interface Recipes {
  GoogleFontCacheOptions: any;
  googleFontsCache: any;
  imageCache: any;
  ImageCacheOptions: any;
  offlineFallback: any;
  OfflineFallbackOptions: any;
  pageCache: any;
  PageCacheOptions: any;
  staticResourceCache: any;
  StaticResourceOptions: any;
  warmStrategyCache: any;
  WarmStrategyCacheOptions: any;
}

// endregion

export default Workbox;

export {}